<template>
  <el-dialog
    :title="title"
    :lock-scroll="false"
    :visible="dialogVisible"
    class="normal-dialog"
    width="630px"
    center
    top="10vh"
    @close="handleClose"
  >
    <div v-loading="loading">
      <div v-if="isshow" class="settled">
        <span>以下账单无法上传凭证，可能有以下原因</span>
        <div>常规结算：已结算或未确认的账单无法上传支付凭证</div>
        <div>定金结算：未确认或可结算金额小于或等于0的无法上传支付凭证</div>
        <div v-for="(item, i) in settledArr " :key="i">{{ item.billName }}</div>
      </div>
      <div v-else>
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="支付凭证：">
            <el-upload
              ref="upload"
              class="upload-demo"
              drag
              action="/"
              accept=".jpg, .png, .pdf"
              :file-list="files"
              :http-request="handleUpload"
              :before-upload="handleBeforeUpload"
              :on-exceed="handExceed"
              :on-remove="handleRemove"
              :limit="3"
              multiple
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__text" style="font-size: 12px;margin-top: 5px;">最多上传3张，格式JPG、PNG、PDF</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="凭证金额：" prop="paymentAmount">
            <el-input v-model="form.paymentAmount" size="small" placeholder="请输入凭证金额" style="width: 160px;" @input="onInput(form.paymentAmount, formData.residueAmount)"></el-input>
          </el-form-item>
        </el-form>
        <el-table
          ref="topUpTable"
          :data="unsettled"
          style="width: 100%"
          size="mini"
          border
        >
          <el-table-column
            prop="billNo"
            label="账单编号"
            align="center"
            width="130"
          ></el-table-column>
          <el-table-column
            prop="billName"
            label="账单名称"
            align="center"
            minWidth="140"
          ></el-table-column>
          <el-table-column
            label="结算金额"
            align="center"
            width="130"
          >
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.residueAmount" :min="0.01" :max="scope.row.residueAmount" style="width: 100px" size="mini" controls-position="right"></el-input-number>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer" class="normal-dialog__footer">
      <el-button v-if="isshow && unsettled.length > 0" type="primary" size="small" @click="continueUploading">其他账单继续上传</el-button>
      <el-button v-if="!isshow" type="success" size="small" @click="onConfirm">确认</el-button>
      <el-button size="small" @click="handleClose">{{ isshow ? '重新选择' : '取消' }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  uploadBatchPayment
} from '@/api/order/order'
export default {
  props: {
    formData: {
      type: Object,
      require: true,
      default: () => ({})
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      isshow: true, // 显示变量
      title: '', // 弹窗标题
      settledArr: [], // 已结算容器
      unsettled: [], // 未结算容器
      files: [], // 上传文件容器
      form: { // 结算金额和备注的字段容器
        paymentAmount: undefined // 凭证金额
      },
      rules: {
        paymentAmount: [
          { required: true, message: '请输入凭证金额', trigger: 'blur' }
        ]
      },
    }
  },
  created() {
    this.formData.listData.forEach(v => {
      if (!v.isUploadPayment && v.billState != 2) {
        this.unsettled.push(v)
      } else {
        this.settledArr.push(v)
      }
    })
    if (this.settledArr.length != 0) {
      this.isshow = true
      this.title = '提示'
    } else {
      this.isshow = false
      this.title = '批量上传支付凭证'
    }
  },
  methods: {
    // input 数字限制
    onInput(value) {
      this.form.paymentAmount = value.replace(/[^0-9.]/g, '').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      // if (value > oldValue) this.form.residueAmount = oldValue
    },
    handleBeforeUpload(raw) {
      const fileSuffix = raw.name.substring(raw.name.lastIndexOf('.') + 1)
      const whiteList = ['pdf', 'png', 'jpg']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件只能是 JPG、PNG、PDF格式')
        return false
      }
      if (raw.size / 1024 / 1024 > 2) {
        this.$message.error('上传图片大小不能超过 2MB!')
        return false
      }
    },
    handleUpload(raw) {
      this.files.push(raw.file)
    },
    handExceed(files, fileList) {
      this.$message({
        type: 'warning',
        message: `当前限制选择 3 个文件，共选择了 ${files.length + fileList.length} 个文件.`
      })
      return
    },
    handleRemove(file, fileList) {
      this.files = fileList
    },
    // 继续上传
    continueUploading() {
      this.isshow = false
      this.title = '批量上传支付凭证'
    },
    // 确认
    onConfirm() {
      if (this.files.length == 0) return this.$message.error('请上传文件后，在确认提交！')
      if (this.form.paymentAmount == 0) return this.$message.error('凭证金额必须大于0，请重新输入！')
      let isshow = false
      let arr = []
      this.unsettled.forEach(v => {
        if (!v.residueAmount) isshow = true
        arr.push({
          billNo: v.billNo,
          currentAmount: v.residueAmount
        })
      })
      if (isshow) return this.$message.error('请填写结算金额！')
      let formData = new FormData()
      this.files.forEach(item => {
        formData.append('files', item)
      })
      formData.append('dtoList', JSON.stringify(arr))
      formData.append('paymentAmount', Number(this.form.paymentAmount))
      this.loading = true
      uploadBatchPayment(formData).then(res => {
        this.loading = false
        if (res.code == '00000') {
          this.handleClose()
          this.$emit('getList')
          this.$message({
            message: '操作成功',
            type: 'success'
          })
        } else {
          this.files = []
          this.$refs.upload.clearFiles()
          this.$message.error(res.desc)
        }
      }).catch(() => {
        this.loading = false
        this.files = []
        this.$refs.upload.clearFiles()
      })
    },
    handleClose() {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.settled {
 display: flex;
 flex-direction: column;
 align-items: center;
 span {
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 20px;
 }
 div {
  margin-bottom: 10px;
 }
}
::v-deep .el-upload-dragger {
  height: 220px;
}
</style>
