<template>
  <el-dialog
    :title="title"
    :lock-scroll="false"
    class="normal-dialog"
    :visible="dialogVisible"
    width="550px"
    center
    top="10vh"
    @close="handleClose"
  >
    <div v-loading="loading">
      <div>
        <el-card v-for="(item, index) in lookArr" :key="index" class="box-card" style="margin-bottom: 10px">
          <div class="content" style="justify-content: space-between;align-items: center;">
            <span class="CredentialsText">{{ item.createTime }}</span>
            <div>
              <span class="CredentialsAudit" :style="item.auditState == 1 ? 'color:#67C23A;' : ''">{{ auditStateText(item.auditState) }}</span>
              <el-popover
                v-if="item.auditState == 2"
                :content="item.auditRemark"
                placement="top"
                width="200"
                trigger="hover"
              >
                <span slot="reference" style="color: #F59A23;margin-left: 10px;">原因</span>
              </el-popover>
            </div>
          </div>
          <div class="content">
            <div class="CredentialsText">支付凭证:</div>
            <div>
              <div v-for="(items, i) in item.paymentFileVOList" :key="i" class="content-content">
                <span style="cursor: pointer;" @click="onDownload(items)">{{ items.fileName }}</span>
              </div>
            </div>
          </div>
          <div class="content">
            <span class="CredentialsText">凭证金额:</span>
            <span>{{ item.paymentAmount }}</span>
          </div>
          <div class="content">
            <span class="CredentialsText">结算金额:</span>
            <span>{{ item.amount }}</span>
          </div>
          <div class="content">
            <span class="CredentialsText">说明备注:</span>
            <span>{{ item.remark ? item.remark : '-' }}</span>
          </div>
          <div class="content">
            <span class="CredentialsText">上传操作人:</span>
            <span>{{ item.createName }}</span>
          </div>
        </el-card>
      </div>
    </div>
    <span slot="footer" class="normal-dialog__footer">
      <el-button size="small" @click="handleClose">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { queryPayment } from '@/api/order/order'
export default {
  props: {
    formData: {
      type: Object,
      require: true,
      default: () => ({})
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      title: '', // 标题
      auditStateType: [ // 支付凭证审核状态
        {
          value: 0,
          label: '待审核'
        }, {
          value: 1,
          label: '审核通过'
        }, {
          value: 2,
          label: '审核驳回'
        }
      ],
      lookArr: []
    }
  },
  created() {
    this.title = this.formData.title
    this.getList()
  },
  methods: {
    getList() {
      let obj = {
        billNo: this.formData.billNo
      }
      this.loading = true
      if (this.formData.state == 1) obj.state = 1
      queryPayment(obj).then(res => {
        this.loading = false
        if (res.code == '00000') {
          this.lookArr = res.data
        } else {
          this.$message.error(res.desc)
        }
      }).catch(res => {
        this.loading = false
        this.$message.error(res.desc)
      })
    },
    // 审核状态文字
    auditStateText(value) {
      const obj = this.auditStateType.find(
        (item) => String(item.value) === String(value)
      )
      return (obj && obj.label) || '暂无数据'
    },
    // 下载文件
    exportFile(data, fileName) {
      const callback = (data) => {
        // 创建a标签，使用 html5 download 属性下载，
        const link = document.createElement('a')
        // 创建url对象
        const objectUrl = window.URL.createObjectURL(new Blob([data]))
        link.style.display = 'none'
        link.href = objectUrl
        // 自定义文件名称， fileName
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        // 适当释放url
        window.URL.revokeObjectURL(objectUrl)
      }
      // 把接口返回的url地址转换为 blob
      const xhr = new XMLHttpRequest()
      xhr.open('get', data, true)
      xhr.responseType = 'blob'
      xhr.onload = () => {
        // 返回文件流，进行下载处理
        callback(xhr.response)
      }
      xhr.send()
    },
    onDownload(data) {
      try {
        this.exportFile(data.fileUrl, data.fileName) // 调用方式
      } catch (err) {
        // 兼容模式下，IE
        // const exportBlob = new Blob([data])
        if (navigator.userAgent.indexOf('Trident') > -1) {
          window.navigator.msSaveBlob(data.fileUrl, data.fileName)
        } else {
          this.exportFile(data.fileUrl, data.fileName) // 调用方式
        }
      }
    },
    handleClose() {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.amount {
  display: flex;
  align-items: center;
}
.content-content {
  flex: 1;
  width: 360px;
  margin-bottom: 10px;
  color: rgb(44, 139, 229);
}
.content {
  display: flex;
  margin-bottom: 14px;
}
.CredentialsText {
  font-size: 16px;
  margin-right: 20px;
  color: #545353;
}
.CredentialsAudit {
  color: rgb(237, 118, 132);
  font-size: 16px;
}
.popover-div {
  display: flex;
  flex-wrap : wrap;
}
::v-deep .el-upload-dragger {
  height: 220px;
}
</style>
