<template>
  <div class="myBill-page">
    <Header seet="4" />
    <el-row type="flex">
      <el-col :span="3"></el-col>
      <el-col style="z-index: 3">
        <div class="breadcrumb-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>账号中心</el-breadcrumb-item>
            <el-breadcrumb-item>账单列表</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="content-box">
          <LeftHurdle style="margin-right: 20px" />
          <div class="box-rights" v-loading="loading">
            <div class="box-right-top">账单列表</div>
            <div>
              <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="账单编号:">
                  <el-input
                    v-model="form.billNo"
                    placeholder="请输入账单编号"
                    size="mini"
                    clearable
                    style="width: 180px;"
                  ></el-input>
                </el-form-item>
                <el-form-item label="账单名称:">
                  <el-input
                    v-model="form.billName"
                    placeholder="请输入账单名称"
                    size="mini"
                    clearable
                    style="width: 180px;"
                  ></el-input>
                </el-form-item>
                <el-form-item label="创建人:">
                  <el-input
                    v-model="form.createName"
                    placeholder="请输入创建人"
                    size="mini"
                    clearable
                    style="width: 140px;"
                  ></el-input>
                </el-form-item>
                <el-form-item label="创建时间:">
                  <el-date-picker
                    v-model="timeSearch1"
                    :default-time="['00:00:00', '23:59:59']"
                    size="mini"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 200px !important;"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="收款时间:">
                  <el-date-picker
                    v-model="timeSearch2"
                    :default-time="['00:00:00', '23:59:59']"
                    size="mini"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 200px !important;"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="支付凭证:">
                  <el-select
                    v-model="form.isPaymentVoucher"
                    placeholder="请选择"
                    size="mini"
                    style="width: 90px !important;"
                    @change="onScreen"
                  >
                    <el-option
                      v-for="item in payVoucherArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="结算状态:">
                  <el-select
                    v-model="form.settleState"
                    placeholder="请选择"
                    size="mini"
                    style="width: 100px !important;"
                    @change="onScreen"
                  >
                    <el-option
                      v-for="item in payStatusArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="账单状态:">
                  <el-select
                    v-model="form.billState"
                    placeholder="请选择"
                    size="mini"
                    style="width: 100px !important;"
                    @change="onScreen"
                  >
                    <el-option
                      v-for="item in billingStatusArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="支付方式:">
                  <el-select
                    v-model="form.payType"
                    placeholder="请选择"
                    size="mini"
                    style="width: 90px !important;"
                    @change="onScreen"
                  >
                    <el-option
                      v-for="item in payTypeArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <div class="button">
                  <div class="search-btn2" @click="onResetting()">重置</div>
                  <div class="search-btn" @click="getList()">查询</div>
                </div>
                <!-- <div class="button">
                  <div style="width: 140px;" class="search-btn1" @click="onBatchUploadPaymentVoucher()">批量上传支付凭证</div>
                </div> -->
                <div class="button">
                  <div style="width: 110px;" class="search-btn1" @click="onExportOrderDetails()"
                    :class="{
                        notAllowed: onExportBtn
                    }" 
                  >
                    <i v-if="onExportBtn" class="el-icon-loading"></i>
                    导出账单明细
                  </div>
                </div>
              </el-form>
            </div>
            <div class="table-box">
              <el-table
                ref="topUpTable"
                :data="tableData"
                :header-cell-style="{
                  'background-color': '#ccc',
                  color: '#000000d9'
                }"
                style="width: 100%"
                size="mini"
                border
                @selection-change="handleSelectionChange"
              >
              <el-table-column
                type="selection"
                align="center"
                width="50">
              </el-table-column>
                <el-table-column
                  prop="billNo"
                  label="账单编号"
                  align="center"
                  width="130"
                ></el-table-column>
                <el-table-column
                  prop="billName"
                  label="账单名称"
                  align="center"
                  min-width="140"
                ></el-table-column>
                <!-- <el-table-column
                  prop="companyId"
                  label="企业编号"
                  align="center"
                  min-width="80"
                ></el-table-column>
                <el-table-column
                  prop="companyName"
                  label="企业名称"
                  align="center"
                  min-width="130"
                ></el-table-column> -->
                <el-table-column
                  prop="billAmount"
                  label="账单汇总金额"
                  align="center"
                  min-width="100"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row.billAmount < 0" style="color: red;cursor: pointer;">
                      <el-tooltip class="item" effect="dark" content="当前为退款账单!" placement="top">
                        <span style="color: red;">{{ scope.row.billAmount }}</span>
                      </el-tooltip>
                    </div>
                    <div v-else>{{ scope.row.billAmount }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="residueAmount"
                  label="剩余可上传金额"
                  align="center"
                  min-width="110"
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.residueAmount }}</div>
                    <!-- <div v-if="scope.row.residueAmount < 0" style="color: red;cursor: pointer;">
                      <el-tooltip class="item" effect="dark" content="当前为退款账单!" placement="top">
                        <span style="color: red;">{{ scope.row.residueAmount }}</span>
                      </el-tooltip>
                    </div>
                    <div v-else>{{ scope.row.residueAmount }}</div> -->
                  </template>
                </el-table-column>
                <el-table-column
                  prop="residueSettleAmount"
                  label="剩余可结算金额"
                  align="center"
                  min-width="110"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row.residueSettleAmount < 0" style="color: red;cursor: pointer;">
                      <el-tooltip class="item" effect="dark" content="当前为应退金额!" placement="top">
                        <span style="color: red;">{{ scope.row.residueSettleAmount }}</span>
                      </el-tooltip>
                    </div>
                    <div v-else>{{ scope.row.residueSettleAmount }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createName"
                  label="创建人"
                  align="center"
                  min-width="90"
                ></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="创建时间"
                  align="center"
                  min-width="140"
                ></el-table-column>
                <el-table-column
                  label="支付方式"
                  align="center"
                  width="80"
                >
                  <template slot-scope="scope">
                    <span>{{ onText(scope.row.payType, payTypeArr) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="结算状态"
                  align="center"
                  width="80"
                >
                  <template slot-scope="scope">
                    <span>{{ onText(scope.row.settleState, payStatusArr) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="支付凭证"
                  align="center"
                  width="80"
                >
                  <template slot-scope="scope">
                    <span>{{ onText(scope.row.isPaymentVoucher, payVoucherArr) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="账单状态"
                  align="center"
                  width="80"
                >
                  <template slot-scope="scope">
                    <span>{{ onText(scope.row.billState, billingStatusArr) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="receiveTime"
                  label="收款时间"
                  align="center"
                  min-width="140"
                ></el-table-column>
                <el-table-column
                  prop="stateUpdateName"
                  label="状态操作者"
                  align="center"
                  width="160"
                ></el-table-column>
                <el-table-column
                  prop="stateUpdateTime"
                  label="账单状态操作时间"
                  align="center"
                  min-width="140"
                ></el-table-column>
                <el-table-column
                  fixed="right"
                  label="操作"
                  align="center"
                  width="170">
                  <template slot-scope="scope">
                    <el-button style="padding: 5px 7px;margin: 2px 2px;" size="mini" @click="onViewDetails(scope.row)">查看详情</el-button>
                    <el-button v-if="scope.row.billState == 0" style="padding: 5px 7px;margin: 2px 2px;" type="primary" size="mini" @click="onConform(scope.row)">确认账单</el-button>
                    <el-button v-if="scope.row.billState == 0" style="padding: 5px 7px;margin: 2px 2px;" type="danger" size="mini" @click="onReject(scope.row)">驳回账单</el-button>
                    <el-button 
                      v-if="!scope.row.isUploadPayment"
                      style="padding: 5px 7px;margin: 2px 2px;"
                      type="primary"
                      size="mini"
                      @click="onUploadPaymentVoucher(scope.row)"
                    >上传支付凭证</el-button>
                    <el-button 
                      v-if="scope.row.isPaymentVoucher == 1 && scope.row.billState != 3"
                      style="padding: 5px 7px;margin: 2px 2px;"
                      type="success"
                      size="mini"
                      @click="lookPaymentVoucher(scope.row)"
                    >查看支付凭证</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="tablePage">
                <el-pagination
                  background
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="3"></el-col>
    </el-row>
    <Footer />
    <!-- 上传支付凭证弹窗 -->
    <Upload
      v-if="dialogVisibleUpload"
      :form-data="formData"
      :dialog-visible.sync="dialogVisibleUpload"
      @getList="getList"
    ></Upload>
    <!-- 批量上传支付凭证弹窗 -->
    <Batch
      v-if="dialogVisibleBatch"
      :form-data="formData"
      :dialog-visible.sync="dialogVisibleBatch"
      @getList="getList"
    ></Batch>
    <!-- 查看支付凭证弹窗 -->
    <ViewCredentials
      v-if="dialogVisibleViewCredentials"
      :form-data="formData"
      :dialog-visible.sync="dialogVisibleViewCredentials"
    ></ViewCredentials>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import Upload from './components/uploadPaymentVoucher.vue'
import ViewCredentials from './components/viewCredentials.vue'
import Batch from './components/batchUploadPaymentVoucher.vue'
import {
  billQueryBillList,
  confirmBill,
  cancelBill,
  exportMyBillList
} from '@/api/order/order'
export default {
  components: {
    Header,
    Footer,
    LeftHurdle,
    Upload,
    ViewCredentials,
    Batch
  },
  data() {
    return {
      loading: false,
      payVoucherArr: [ // 支付凭证
        {
          value: undefined,
          label: '全部'
        }, {
          value: 0,
          label: '未上传'
        }, {
          value: 1,
          label: '已上传'
        }
      ],
      payStatusArr: [ // 结算状态
        {
          value: undefined,
          label: '全部'
        }, {
          value: 0,
          label: '未结算'
        }, {
          value: 1,
          label: '部分结算'
        }, {
          value: 2,
          label: '已结算'
        }
      ],
      billingStatusArr: [ // 账单状态
        {
          value: undefined,
          label: '全部'
        }, {
          value: 0,
          label: '待确认'
        }, {
          value: 1,
          label: '已确认'
        }, {
          value: 2,
          label: '已驳回'
        }, {
          value: 3,
          label: '已关闭'
        }
      ],
      payTypeArr: [ // 支付方式
        {
          value: undefined,
          label: '全部'
        }, {
          value: 0,
          label: '常规结算'
        }, {
          value: 1,
          label: '定金结算'
        // }, {
        //   value: 2,
        //   label: '退款'
        }
      ],
      formData: {}, // 弹窗需要的数据
      dialogVisibleUpload: false, // 上传支付凭证弹窗
      dialogVisibleViewCredentials: false, // 查看支付凭证弹窗
      dialogVisibleBatch: false, // 批量上传支付凭证弹窗
      timeSearch1: [], // 时间搜索 创建时间
      timeSearch2: [], // 时间搜索 收款时间
      form: {}, // 搜索数据
      tableData: [], // 列表数据
      currentPage: 1, // 当前页
      pageSize: 20, // 页条数
      total: 0, // 总页数
      onExportBtn: false, // 导出按钮限制
      batchArr: []  //  勾选数据容器
    }
  },
  mounted() {
    if (this.$route.params.searchId) {
        this.form.billNo = this.$route.params.searchId
    }
    this.getList()
  },
  methods: {
    getList() {
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        ...this.form
      }
      if (this.timeSearch1 != null && this.timeSearch1.length > 0) {
        data.createStartTime = this.timeSearch1[0]
        data.createEndTime = this.timeSearch1[1]
      }
      if (this.timeSearch2 != null && this.timeSearch2.length > 0) {
        data.receiveStartTime = this.timeSearch2[0]
        data.receiveEndTime = this.timeSearch2[1]
      }
      this.loading = true
      billQueryBillList(data).then((res) => {
        this.loading = false
        if (res.code == '00000') {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.$message({ message: res.desc, type: 'error' })
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 重置
    onResetting() {
      this.form = {}
      this.timeSearch1 = []
      this.timeSearch2 = []
      this.getList()
    },
    // 导出账单明细
    onExportOrderDetails() {
      let arr = this.batchArr.map((item) => {
        return item.billNo
      })
      let data = {
        pageNum: this.currentPage,
        pageSize: 999,
        billNoList: arr,
        ...this.form
      }
      if (this.timeSearch1 != null && this.timeSearch1.length > 0) {
        data.createStartTime = this.timeSearch1[0]
        data.createEndTime = this.timeSearch1[1]
      }
      if (this.timeSearch2 != null && this.timeSearch2.length > 0) {
        data.receiveStartTime = this.timeSearch2[0]
        data.receiveEndTime = this.timeSearch2[1]
      }
      this.onExportBtn = true
      exportMyBillList(data).then( () => {
          this.onExportBtn = false
      }).catch( res => {
          this.onExportBtn = false
          this.$message.error(res.desc)
      })
    },
    onScreen() {
      this.currentPage = 1
      this.getList()
    },
    // 查看详情
    onViewDetails(data) {
      window.open(`/workbench/billDetails?billNo=${data.billNo}`,' _blank')
    },
    // 是否显示上传支付凭证按钮
    isshowUploadPaymentVoucher(data) {
      if (data.billState == 1) {
        return !data.payType ? !data.isPaymentVoucher : data.settleState != 2
      } else {
        return false
      }
    },
    // 查看支付凭证
    lookPaymentVoucher(data) {
      this.formData = {
        type: 1,
        title: '查看汇款凭证',
        billNo: data.billNo
      }
      this.dialogVisibleViewCredentials = true
    },
    handleSelectionChange(data) {
      this.batchArr = data
    },
    // 批量上传支付凭证
    onBatchUploadPaymentVoucher() {
      if (this.batchArr.length == 0 || this.batchArr == []) return this.$message.error('请先勾选列表！')
      let companyId = this.batchArr[0].companyId
      let isshow = true
      this.batchArr.forEach(v => {
        if (v.companyId != companyId) isshow = false
      })
      if (!isshow) return this.$message.error('请选择相同企业的账单！')
      this.formData.listData = this.batchArr
      this.dialogVisibleBatch = true
    },
    // 上传支付凭证
    onUploadPaymentVoucher(data) {
      this.formData = {
        type: 1, // 查看的类型
        residueAmount: data.residueAmount, // 最大结算金额
        residueSettleAmount: data.residueSettleAmount, // 剩余可结算金额
        payType: data.payType, // 支付方式
        billAmount: data.billAmount, // 账单汇总金额
        title: '上传汇款凭证', // 弹窗标题
        billNo: data.billNo // 账单ID
      }
      this.dialogVisibleUpload = true
    },
    // 确认账单
    onConform(data) {
      this.$confirm('确定通过该账单？','确认账单', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        confirmBill(data.billNo).then((res) => {
          if (res.code == '00000') {
            this.getList()
            this.$message({
              type: 'success',
              message: "确认成功"
            })
          } else {
            this.$message({ message: res.desc, type: 'error' })
          }
        })
      })
    },
    // 驳回账单
    onReject(data) {
      this.$confirm('确定驳回该账单？','驳回账单', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        cancelBill(data.billNo).then((res) => {
          if (res.code == '00000') {
            this.getList()
            this.$message({
              type: 'success',
              message: "驳回成功"
            })
          } else {
            this.$message({ message: res.desc, type: 'error' })
          }
        })
      })
    },
    // 文字显示
    onText(value, arr) {
      const obj = arr.find(
        (item) => String(item.value) === String(value)
      )
      return (obj && obj.label) || '暂无数据'
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.myBill-page {
  .el-form-item {
    margin-bottom: 5px !important;
  }
  .el-form {
    display: flex;
    margin-left: 20px;
    flex-wrap : wrap;
  }
  .button {
    display: flex;
    align-items: center;
    padding-bottom: 5px !important;
    div {
      border-radius: 8px;
      border: 1px solid #000;
      text-align: center;
      width: 80px;
      height: 25px;
      line-height: 25px;
      cursor: pointer;
      margin-left: 10px;
    }
    .search-btn {
      border-color: #cd9f49;
      background-color: #cd9f49;
      color: #fff;
    }
    .search-btn1 {
      border-color: #6695ed;
      background-color: #6695ed;
      color: #fff;
    }
    .search-btn2 {
      border-color: #bfbfc3;
      background-color: #fff;
      color: #2f3031;
    }
  }
  .breadcrumb-box {
    margin-top: 30px;
    padding-bottom: 20px;
    .el-breadcrumb {
      font-size: 18px;
    }
  }
  .content-box {
    display: flex;
    .box-rights {
      width: 1150px !important;
      border: 1px solid #d7d7d7;
      border-radius: 10px;
      .box-right-top {
        height: 40px;
        line-height: 40px;
        padding-left: 40px;
        background: #d7d7d7;
        border-bottom: 1px solid #d7d7d7;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .table-box {
        padding: 5px 0 20px;
        .tablePage {
          display: flex;
          justify-content: flex-end;
          padding: 25px;
        }
      }
    }
  }
}
// 分页样式
::v-deep .el-pagination.is-background .el-pager {
  .active {
    background-color: #cd9f49 !important;
  }
  li:hover {
    color: #cd9f49 !important;
  }
}
</style>