<template>
  <el-dialog
    :title="formData.title"
    :lock-scroll="false"
    class="normal-dialog"
    :visible="dialogVisible"
    width="610px"
    center
    top="10vh"
    @close="handleClose"
  >
    <div v-loading="loading">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="支付凭证：">
          <el-upload
            ref="upload"
            class="upload-demo"
            drag
            action="/"
            accept=".jpg, .png, .pdf"
            :file-list="files"
            :http-request="handleUpload"
            :before-upload="handleBeforeUpload"
            :on-exceed="handExceed"
            :on-remove="handleRemove"
            :limit="3"
            multiple
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__text" style="font-size: 12px;margin-top: 5px;">最多上传3张，格式JPG、PNG、PDF</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="凭证金额：" prop="paymentAmount">
          <el-input v-model="form.paymentAmount" size="small" placeholder="请输入凭证金额" style="width: 160px;" @input="onInput(form.amount, formData.residueAmount)"></el-input>
        </el-form-item>
        <el-form-item label="结算金额：" prop="amount">
          <el-input v-model="form.amount" size="small" placeholder="请输入结算金额" style="width: 160px;" @input="onInput1(form.amount, formData.residueAmount)"></el-input>
        </el-form-item>
        <el-form-item label="说明备注：">
          <el-input v-model="form.remark" type="textarea" :rows="5" size="small" placeholder="请输入说明备注" style="width: 360px;"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="normal-dialog__footer">
      <el-button type="success" size="small" @click="onConfirm">确认</el-button>
      <el-button size="small" @click="handleClose">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { uploadPayment2 } from '@/api/order/order'
export default {
  props: {
    formData: {
      type: Object,
      require: true,
      default: () => ({})
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      files: [], // 上传文件容器
      form: { // 结算金额和备注的字段容器
        amount: undefined, // 结算金额
        paymentAmount: undefined, // 凭证金额
        remark: '' // 备注
      },
      rules: { // 必传校验
        amount: [
          { required: true, message: '请输入结算金额', trigger: 'blur' }
        ],
        paymentAmount: [
          { required: true, message: '请输入凭证金额', trigger: 'blur' }
        ]
      },
      lookArr: []
    }
  },
  created() {
    this.form.amount = this.formData.residueAmount
  },
  methods: {
    // input 数字限制
    onInput(value) {
      this.form.paymentAmount = value.replace(/[^0-9.]/g, '').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      // if (value > oldValue) this.form.amount = oldValue
    },
    onInput1(value) {
      this.form.amount = value.replace(/[^0-9.]/g, '').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      // if (value > oldValue) this.form.amount = oldValue
    },
    handleBeforeUpload(raw) {
      const fileSuffix = raw.name.substring(raw.name.lastIndexOf('.') + 1)
      const whiteList = ['pdf', 'png', 'jpg']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件只能是 JPG、PNG、PDF格式')
        return false
      }
      if (raw.size / 1024 / 1024 > 2) {
        this.$message.error('上传图片大小不能超过 2MB!')
        return false
      }
    },
    handleUpload(raw) {
      this.files.push(raw.file)
    },
    handExceed(files, fileList) {
      this.$message({
        type: 'warning',
        message: `当前限制选择 3 个文件，共选择了 ${files.length + fileList.length} 个文件.`
      })
      return
    },
    handleRemove(file, fileList) {
      this.files = fileList
    },
    // 确认
    onConfirm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.files.length == 0) return this.$message.error('请上传文件后，在确认提交！')
          if (this.form.paymentAmount == 0) return this.$message.error('凭证金额必须大于0，请重新输入！')
          if (this.form.paymentAmount < this.form.amount) return this.$message.error('结算金额不得大于凭证金额！')
          let formData = new FormData()
          this.files.forEach(item => {
            formData.append('files', item)
          })
          formData.append('billNo', this.formData.billNo)
          formData.append('remark', this.form.remark)
          formData.append('paymentAmount', Number(this.form.paymentAmount))
          formData.append('amount', Number(this.form.amount))
          this.loading = true
          uploadPayment2(formData).then(res => {
            this.loading = false
            if (res.code == '00000') {
              this.handleClose()
              this.$emit('getList')
              this.$message({
                message: '操作成功',
                type: 'success'
              })
            } else {
              this.files = []
              this.$refs.upload.clearFiles()
              this.$message.error(res.desc)
            }
          }).catch(() => {
            this.loading = false
            this.files = []
            this.$refs.upload.clearFiles()
          })
        }
      })
    },
    handleClose() {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.popover-div {
  display: flex;
  flex-wrap : wrap;
}
::v-deep .el-upload-dragger {
  height: 220px;
}
</style>
